import gql from 'graphql-tag';

export default gql`
query getAllChildPageIdsOfFolder($folderId: Int!) {
  folders(where: {folder_id: {_eq: $folderId}}) {
    child_pages(order_by: {order_index: asc}) {
      page_id
      published
      slug
      name
      thumbnails
      item_path
    }
  }
}
`;
